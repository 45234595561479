<template>
  <div :class="{ 'w-80': inTable }">
    <div v-if="!inTable" class="secondhead-text pb-2">Messages</div>
    <div v-if="loading" class="pb-2">
      <loading-spinner />
    </div>
    <div
      v-else-if="notes.length"
      v-for="note in latest ? latest_message : notes"
      :key="note.created"
      :class="{ 'border-t border-cool-gray': !inTable, 'py-2': true }"
    >
      <div v-if="!inTable">
        <strong>{{ note.author.firstname }} {{ note.author.lastname }}</strong>
        -
        {{ FormatDateTime(note.added, true) }}
      </div>
      <div class="px-2 py-2">
        <i>{{
          note.text.length ? note.text : "Message content not available"
        }}</i>
      </div>
    </div>
    <div v-else class="pb-2">
      No messages available for this fieldwork entry.
    </div>
    <div v-if="!inTable" class="border-t border-cool-gray">
      <div>
        <div class="thirdhead-text pt-2">Add a Message</div>
        <div
          v-if="
            isStu() || (isMasquerade() && !this.$route.path.match('/admin/*'))
          "
          class="pb-3"
        >
          Use the box below to reply any admin messages.
        </div>
        <div
          v-if="
            (isAdm() || isSaj() || isFac()) &&
            this.$route.path.match('/admin/*')
          "
          class="pb-3"
        >
          Include a message to this student on their entry.
        </div>
        <div class="pb-3">
          <textarea
            class="w-full text-base bg-white bg-clip-padding border p-1 rounded"
            placeholder="Enter message..."
            v-model="message_to_send"
          />
        </div>
        <div class="flex justify-end">
          <button
            class="inline-block py-1 px-5 bg-purple hover:bg-dark-purple rounded-full text-white"
            @click="addMessage"
          >
            {{ message_to_send ? "Add Message" : "Message Empty" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isAdm,
  isFac,
  isSaj,
  isStu,
  isMasquerade,
} from "@/composables/tokenUtils";
import { get, post } from "@/composables/httpUtil";
import {FormatDateTime, parseDate} from "@/composables/date_utils";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "EntryMessages",
  components: { LoadingSpinner },
  props: {
    id: {
      type: String,
      required: true,
    },
    latest: {
      type: Boolean,
      default: false,
    },
    inTable: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      loading: false,
      error: undefined,

      message_to_send: "",
      notes: [],
      latest_message: [],
    };
  },
  created: function () {
    this.loading = true;
    this.getNotes();
  },
  methods: {
    FormatDateTime,
    isAdm,
    isFac,
    isSaj,
    isStu,
    isMasquerade,
    // timeDiffCalc: function (datetime) {
    //   console.log(datetime, typeof datetime);
    //   let today = new Date();
    //   datetime = new Date(datetime);
    //   let timeDiffMilliseconds = Math.abs(today - datetime.getTime());
    //   const days = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));
    //   const hours = Math.floor(
    //     (timeDiffMilliseconds % (1000 * 3600 * 24)) / (1000 * 3600)
    //   );
    //   const minutes = Math.floor(
    //     (timeDiffMilliseconds % (1000 * 3600)) / (1000 * 60)
    //   );
    //   return `${days} days, ${hours} hours, ${minutes} minutes ago`;
    // },
    addMessage: function () {
      if (this.message_to_send) {
        let url = `student/fieldworks/${this.id}/annotation`;
        if (isAdm() || isSaj() || isFac()) {
          url = `admin/fieldworks/${this.id}/annotation`;
        }
        post(url, { message: this.message_to_send })
          .then((res) => {
            this.notes.push(res);
            this.message_to_send = "";
          })
          .catch((err) => {
            console.log(err);
            this.error = err;
          });
      }
    },
    getNotes: function () {
      let url = `student/fieldworks/${this.id}/annotation`;
      if (isAdm() || isSaj() || isFac()) {
        url = `admin/fieldworks/${this.id}/annotation`;
      }
      get(url)
        .then((notes) => {
          this.notes = notes.data.sort((a, b) => parseDate(a.added, true) - parseDate(b.added, true));
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.error = err;
        });
    },
  },
};
</script>

<style scoped></style>
