<template>
  <div class="box-style" v-if="loading">
    <loading-spinner />
  </div>
  <div
    v-else
    aria-labelledby="fieldworks view page"
    ref="fieldworksView"
    data-cy="header-fieldworks-view"
  >
    <div class="">
      <div class="flex justify-between">
        <button
          aria-label="Return to the previous page"
          class="header-text hover:cursor-pointer"
          data-cy="header-fieldworks-view"
          @click="$router.push({ name: fromRoute })"
        >
          {{ header }}
        </button>
        <div
          v-if="
            this.$store.state.user_selected_term.name ===
            this.lookup_current_term.name
          "
        >
          <button
            aria-label="Edit this fieldwork entry"
            class="inline-block py-3 px-9 bg-purple hover:bg-dark-purple rounded-full text-white disabled:bg-slate-gray"
            @click="$router.push(`/fieldworks/edit/${fieldwork?.fieldwork_id}`)"
            :disabled="fromRoute !== 'Fieldworks'"
          >
            Edit
          </button>
        </div>
      </div>
      <div class="pt-5">
        <div class="grid grid-cols-3 gap-6" data-cy="fieldworks-view-info">
          <div class="box-style col-span-3 lg:col-span-2">
            <fieldwork-detail :fieldwork="fieldwork" />
          </div>
          <div
            class="col-span-3 lg:col-span-1 row-start-2 lg:row-start-1 col-start-1 lg:col-start-3"
          >
            <div class="box-style">
              <entry-messages :id="this.id" />
            </div>
          </div>
          <div class="row-start-3 lg:row-start-2 col-span-3 lg:col-span-2">
            <div class="flex justify-between py-5">
              <button
                aria-label="Previous entry"
                class="inline-block py-1.5 px-6 bg-purple hover:bg-dark-purple rounded-full text-white disabled:bg-grey"
                @click="newerFieldwork"
                :disabled="!newer"
              >
                &lt; Newer
              </button>
              <button
                aria-label="Next entry"
                class="inline-block py-1.5 px-8 bg-purple hover:bg-dark-purple rounded-full text-white disabled:bg-grey"
                @click="olderFieldwork"
                :disabled="!older"
              >
                Older &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState, useStore} from "vuex";
import EntryMessages from "@/components/forms/EntryMessages";
import FieldworkDetail from "@/components/reusable-components/FieldworkDetail";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "FieldworksView",
  components: {
    FieldworkDetail,
    EntryMessages,
    LoadingSpinner,
  },
  props: {
    fromRoute: {
      type: String,
      required: false,
      default: "Fieldworks",
    },
  },
  data() {
    return {
      loading: true,
      id: null,
      fieldwork: null,
      header: "My Entries",
      newer: true,
      older: true,
    };
  },
  mounted() {
    const store = useStore();
    this.id = this.$route.params.id;

    // console.log(this.$route.params.id);
    if (store.state.fieldwork_form_store.entries.length === 0) {
      store.dispatch("getFieldworkEntries").then(() => {
        // console.log("GETTING");
        this.fieldwork = this.loadFieldwork(this.$route.params.id);
        this.loading = false;
        // this.$refs.fieldworksView.focus();
      });
    } else {
      // console.log("CACHED");
      this.fieldwork = this.loadFieldwork(this.$route.params.id);
      this.loading = false;
    }
  },
  methods: {
    // TODO: THIS HAS NO BOUNDS CHECKING, THIS IS REPEATED CODE
    // Need to simplify the push functions into single function
    loadFieldwork: function (id) {
      this.older = true;
      this.newer = true;
      if (
        this.computed_index ===
        this.$store.state.fieldwork_form_store.entries.length - 1
      ) {
        this.older = false;
      }
      if (this.computed_index === 0) {
        this.newer = false;
      }

      return this.$store.state.fieldwork_form_store.entries.find(
        (fieldwork) => fieldwork.fieldwork_id === id
      );
    },
    olderFieldwork: function () {
      this.id =
        this.$store.state.fieldwork_form_store.entries[
          this.computed_index + 1
        ].fieldwork_id;
      this.$router
        .push({
          name: "FieldworksView",
          params: {
            id: this.id,
            fromRoute: this.fromRoute,
          },
        })
        .then(() => {
          this.fieldwork = this.loadFieldwork(this.id);
        });
    },
    newerFieldwork: function () {
      this.id =
        this.$store.state.fieldwork_form_store.entries[
          this.computed_index - 1
        ].fieldwork_id;
      this.$router
        .push({
          name: "FieldworksView",
          params: {
            id: this.id,
            fromRoute: this.fromRoute,
          },
        })
        .then(() => {
          this.fieldwork = this.loadFieldwork(this.id);
        });
    },
    ...mapActions(["getFieldworkEntries", "adminPutFieldworkEntry"]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
    ...mapState({
      acad_level: (state) => state.acad_level,
    }),
    computed_index: function () {
      return this.$store.state.fieldwork_form_store.entries.findIndex(
        (fieldwork) => fieldwork?.fieldwork_id === this.id
      );
    },
  },
};
</script>

<style scoped></style>
