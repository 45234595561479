<template>
  <div class="text-2xl">
    <h2 id="fieldwork-status" class="inline-block secondhead-text">Status:</h2>
    {{ fieldwork.event_status == 'TODO' ? 'PENDING REVIEW': fieldwork.event_status }}
  </div>
  <div class="text-2xl">
    <h2 id="fieldwork-added" class="inline-block secondhead-text">Date Submitted:</h2>
    {{ dateSubmitted }}
  </div>
  <div class="text-2xl">
    <h2 id="fieldwork-type" class="inline-block secondhead-text">
      Fieldwork Type:
    </h2>
    {{ fieldwork.fieldwork_type_name }}
  </div>
  <div v-for="(field, idx) in answers" :key="idx">
    <div v-if="field.field_value && field.field_name !== 'Experience ID'">
      <div class="fourthhead-text">
        {{ field.field_name }}
      </div>
      <div :data-cy="field.field_name.replace(/[^A-Z\d]+/gi, '_') + '-' + idx">
        <!--TODO: This can probably be made into a function-->
        {{
          field.field_type.toLowerCase() === "checkbox"
            ? !!field.field_value
            : field["field_value"]
        }}
        {{
          field.field_name.toLowerCase().includes("time") ||
          field.field_name.toLowerCase().includes("prep") ||
          field.field_name.toLowerCase().includes("event") ||
          field.field_name.toLowerCase().includes("travel")
            ? "hours"
            : ""
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { parseDate } from "@/composables/date_utils";

export default {
  name: "FieldworkDetail",
  props: {
    fieldwork: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answers: null,
    };
  },
  methods: {
    parseDate,
    format: function (item_content, item_type) {
      if (item_content === "date") {
        return item_type.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
      } else {
        return item_type;
      }
    },
    loadFields: function () {
      const form = this.lookup_fieldwork_types.find(
        (form) =>
          form.fieldwork_log_type_id === this.fieldwork.fieldwork_type_id
      );

      this.answers = [...form.fields]
        .map((form_field) => {
          let fieldValue = this.fieldwork?.fields.find(
            (entry_field) =>
              entry_field.fieldwork_log_field_id === form_field.field_id
          );
          if (fieldValue) {
            fieldValue = fieldValue.value;
          }

          return {
            field_name: form_field.name,
            field_value: fieldValue,
            field_type: form_field.type,
            display_order: form_field.display_order,
          };
        })
        .sort((a, b) => a.display_order - b.display_order);
    },
  },
  created() {
    this.loadFields();
  },
  watch: {
    fieldwork: {
      handler() {
        this.loadFields();
      },
    },
  },
  computed: {
    ...mapGetters(["form_types", "lookup_fieldwork_types"]),
    dateSubmitted() {
      return this.format(
        "date",
        parseDate(this.fieldwork.added, true)
      );
    },
  },
};
</script>
